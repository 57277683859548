import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import Header from "./Header";
import urls from "../utils/urls.json";
import { SiTiktok } from "react-icons/si";
import { SiInstagram } from "react-icons/si";
import { SiYoutubeshorts } from "react-icons/si";


const ReelsList = () => {
    const [reels, setReels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [totalReels, setTotalReels] = useState(0);
    const API_URL = `${urls.API_URL_PROD}/api/v1/reels-summary`;
    const TOKEN = process.env.REACT_APP_API_TOKEN;
    const LIMIT = 16;

    const fetchReels = useCallback(
        async (page = 1) => {
            setLoading(true);
            const offset = (page - 1) * LIMIT;
            try {
                const response = await axios.get(`${API_URL}?limit=${LIMIT}&offset=${offset}`, {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                    },
                });
                setReels(response.data.data || []);
                setTotalReels(response.data.count || 0);

            } catch (error) {
                console.error("Error fetching reels:", error);
            } finally {
                setLoading(false);
            }
        },
        [API_URL, TOKEN, LIMIT]
    );

    useEffect(() => {
        fetchReels(page);
    }, [page, fetchReels]);

    const handleNextPage = () => {
        if (page * LIMIT < totalReels) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage((prevPage) => prevPage - 1);
        }
    };



    return (
        <div>
            <Header onCategorySelect={(selectedCategory) => console.log("Navigate to", selectedCategory)} />

            <section className="w-full max-w-6xl mx-auto py-6">
                {loading ? (
                    <div className="flex justify-center items-center py-10">
                        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-pink-400"></div>
                    </div>
                ) : (reels.length > 0 && !loading)  ? (
                    <>
                        {/* Grid de Reels */}
                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 lg:gap-6 gap-4">
                            {reels.map((reel) => (
                                <div key={reel.id} className="bg-gray-800 lg:p-2 p-2 rounded-lg">
                                    <img
                                        src={reel.img}
                                        alt={reel.title}
                                        className="rounded-lg w-full lg:h-48 object-cover"
                                    />
                                    <h3 className="text-white lg:mt-4 mt-2 text-sm lg:text-base text-center">{reel.title}</h3>
                                    <div className="flex justify-center lg:mt-4 mt-2 lg:text-base text-sm">
                                        <a href={reel.urls.youtube} target="_blank" rel="noopener noreferrer">
                                            <button className="mr-8 bg-red-700 text-white lg:px-2 lg:py-1 rounded text-2xl hover:text-pink-100 hover:bg-red-500">
                                                <SiYoutubeshorts />
                                            </button>
                                        </a>
                                        <a href={reel.urls.instagram} target="_blank" rel="noopener noreferrer">
                                            <button className="mr-8 bg-pink-400 text-white lg:px-2 lg:py-1 text-2xl rounded hover:text-pink-100 hover:bg-pink-600">                                                <SiInstagram />

                                            </button>
                                        </a>
                                        <a href={reel.urls.tiktok} target="_blank" rel="noopener noreferrer">
                                            <button className="bg-black text-white lg:px-2 lg:py-1 text-2xl rounded hover:text-pink-100 hover:bg-gray-700">
                                                <SiTiktok />
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Botones de paginación */}
                        <div className="flex justify-between mt-6">
                            <button
                                onClick={handlePreviousPage}
                                disabled={page === 1}
                                className={`px-4 py-2 bg-gray-700 text-white rounded-lg ${
                                    page === 1 ? "opacity-50 cursor-not-allowed" : "hover:bg-pink-400"
                                }`}
                            >
                                Página Anterior
                            </button>

                            <button
                                onClick={handleNextPage}
                                disabled={page * LIMIT >= totalReels}
                                className={`px-4 py-2 bg-gray-700 text-white rounded-lg ${
                                    page * LIMIT >= totalReels
                                        ? "opacity-50 cursor-not-allowed"
                                        : "hover:bg-pink-400"
                                }`}
                            >
                                Página Siguiente
                            </button>
                        </div>
                    </>
                ) : (
                    <p className="text-white text-center">No se encontraron reels</p>
                )}
            </section>
        </div>
    );
};

export default ReelsList;
