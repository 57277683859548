import { useNavigate } from "react-router-dom";

const Header = ({ onCategorySelect }) => {
    const navigate = useNavigate();

    const categories = [
        { name: "Ciencias", value: "ciencias" },
        { name: "Tecnología", value: "tecnologia" },
        { name: "Videojuegos", value: "videojuegos" },
        { name: "Historia", value: "historia" },
        { name: "Naturaleza", value: "naturaleza" },
        { name: "Reels", value: "reels" },
    ];

    const handleCategoryClick = (value) => {
        if (value === "reels") {
            navigate("/reels"); // Navega a la ruta de reels
        } else {
            navigate(`/?category=${value}`); // Navega a la ruta principal con la categoría seleccionada
        }
        if (onCategorySelect) onCategorySelect(value);
    };

    return (
        <header className="py-6 px-2 flex flex-col items-center">
            <div className="mb-6">
                <a href="/">
                    <img
                        src="https://d3iaqo7tqsvydr.cloudfront.net/logo_curio.png"
                        className="h-16 md:h-28"
                        alt="Logo del blog"
                    />
                </a>
            </div>
            <div className="flex flex-wrap gap-3 justify-center">
                {categories.map((category, index) => (
                    <button
                        key={index}
                        onClick={() => handleCategoryClick(category.value)}
                        className={`px-4 py-1 rounded-lg lg:text-xl text-white ${
                            category.value === "reels"
                                ? "bg-pink-500 hover:bg-pink-400"
                                : "bg-gray-700 hover:bg-pink-400"
                        }`}
                    >
                        {category.name}
                    </button>
                ))}
            </div>
        </header>
    );
};

export default Header;
