import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import urls from "../utils/urls.json";
import axios from "axios";


const ArticleDetail = () => {
    const { id } = useParams();
    const [article, setArticle] = useState({});
    const API_URL = `${urls.API_URL_PROD}/api/v1/curiosity`;
    const TOKEN = process.env.REACT_APP_API_TOKEN;

    useEffect(() => {
        const getArticle = async () => {
            try {
                const response = await axios.get(`${API_URL}?id=${id}`, {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                    },
                });
                setArticle(response.data.data);
            }
            catch (error) {
                console.error("Error fetching article:", error);
            }
        };
        getArticle();
    }, [id, API_URL, TOKEN]);

    if (!article.body) {
        return <div className="flex justify-center items-center py-72 mt-16">
            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-pink-400"></div>
        </div>
    }

    const procesarTexto = (texto) => {
        const lineas = texto.split("\n");
        const procesado = [];

        lineas.forEach((linea) => {
            if (linea.startsWith("## ")) {
                procesado.push(<h2 className="text-xl font-semibold text-pink-400 text-center" key={procesado.length}>{linea.replace("## ", "")}</h2 >);
            } else if (linea.startsWith("# ")) {
                procesado.push(<h1 key={procesado.length}>{linea.replace("# ", "")}</h1>);
            } else if (linea.startsWith("### ")) {
                procesado.push(<h3 className="font-semibold text-pink-400 text-center" key={procesado.length}>{linea.replace("### ", "")}</h3>);
            } else if (linea.trim() === "") {
                procesado.push(<br key={procesado.length} />);
            } else {
                procesado.push(<p className="text-lg text-justify"  key={procesado.length}>{linea}</p>);
            }
        });

        return procesado;
    };

    return (
        <div className="max-w-4xl mx-auto p-4 md:p-6 lg:p-8 flex flex-col items-center">
            <div className="mb-6">
                <a href="/">
                    <img
                        src="https://d3iaqo7tqsvydr.cloudfront.net/logo_curio.png"
                        className="h-14 md:h-24"
                        alt="Logo del blogg"
                    />
                </a>
            </div>
            <h1 className="lg:text-3xl text-xl font-bold text-center mb-6">{article.title}</h1>
            <img
                src={article.img}
                alt={article.title}
                className="lg:w-[580px] lg:h-[520px] mx-auto rounded-lg object-cover mb-4"
            />
            {procesarTexto(article.body)}
        </div>
    );
};

export default ArticleDetail;