import React from "react";

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-center py-4">
            <p className="text-gray-400">© 2024
                <a className="hover:text-gray-100" href="https://sukiai.sojoj.com/"> SukiAI </a>
                powered by Jonhathan Sojo</p>
        </footer>
    );
};

export default Footer;