import React from "react";
import { Link } from "react-router-dom";

const ArticleCard = ({ title, body, img, id }) => {
    return (
        <div className="flex bg-gray-700 rounded-lg shadow-lg overflow-hidden">
            <div className="w-1/3">
                <img
                    src={img}
                    alt={title}
                    className="w-full h-full object-cover aspect-square"
                />
            </div>
            <div className="w-2/3 p-4">
                <h2 className="lg:text-2xl font-semibold text-white lg:mb-4 mb-2">{title}</h2>

                {/* Cuerpo del artículo con elipsis */}
                <p className="text-gray-200 text-sm lg:line-clamp-4 line-clamp-3 overflow-hidden lg:text-lg max-h-32">
                    {body}
                </p>
                <Link
                    to={`/articulos/${id}`}
                    className="text-pink-400 hover:text-white lg:text-xl mt-2 inline-block"
                >
                    Leer el artículo completo
                </Link>
            </div>
        </div>
    );
};

export default ArticleCard;