import React, { useEffect, useState, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import ArticleCard from "./Card";
import Header from "./Header";
import urls from "../utils/urls.json";

const ArticleList = () => {
    const [articles, setArticles] = useState([]);
    const [category, setCategory] = useState(null);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [totalArticles, setTotalArticles] = useState(0);
    const [searchParams] = useSearchParams(); // Para leer la categoría de la URL
    const API_URL = `${urls.API_URL_PROD}/api/v1/curiosities`;
    const TOKEN = process.env.REACT_APP_API_TOKEN;
    const LIMIT = 7;

    const fetchArticles = useCallback(
        async (category = null, page = 1) => {
            setLoading(true);
            const offset = (page - 1) * LIMIT;
            try {
                const url = category
                    ? `${API_URL}?category=${category}&limit=${LIMIT}&offset=${offset}`
                    : `${API_URL}?limit=${LIMIT}&offset=${offset}`;

                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                    },
                });

                setArticles(response.data.data || []);
                setTotalArticles(response.data.count || 0);
            } catch (error) {
                console.error("Error fetching articles:", error);
            } finally {
                setLoading(false);
            }
        },
        [API_URL, TOKEN, LIMIT]
    );

    useEffect(() => {
        const initialCategory = searchParams.get("category"); // Leer categoría inicial de la URL
        if (initialCategory) {
            setCategory(initialCategory);
            fetchArticles(initialCategory, 1);
        } else {
            fetchArticles(null, 1);
        }
    }, [searchParams, fetchArticles]);

    const handleCategorySelect = (selectedCategory) => {
        setCategory(selectedCategory);
        setPage(1);
        fetchArticles(selectedCategory, 1);
    };

    const handleNextPage = () => {
        if (page * LIMIT < totalArticles) {
            setPage((prevPage) => prevPage + 1);
            fetchArticles(category, page + 1);
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage((prevPage) => prevPage - 1);
            fetchArticles(category, page - 1);
        }
    };

    return (
        <div>
            <Header onCategorySelect={handleCategorySelect} />
            <section className="w-full max-w-4xl mx-auto py-6">
                {loading ? (
                    <div className="flex justify-center items-center py-10">
                        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-pink-400"></div>
                    </div>
                ) : articles.length > 0 ? (
                    <div className="flex flex-col gap-6">
                        {articles.map((article) => (
                            <ArticleCard
                                key={article.id}
                                title={article.title}
                                body={article.body}
                                img={article.img}
                                id={article.id}
                            />
                        ))}

                        <div className="flex justify-between mt-6">
                            <button
                                onClick={handlePreviousPage}
                                disabled={page === 1}
                                className={`px-4 py-2 bg-gray-700 text-white rounded-lg ${
                                    page === 1 ? "opacity-50 cursor-not-allowed" : "hover:bg-pink-400"
                                }`}
                            >
                                Página Anterior
                            </button>

                            <button
                                onClick={handleNextPage}
                                disabled={page * LIMIT >= totalArticles}
                                className={`px-4 py-2 bg-gray-700 text-white rounded-lg ${
                                    page * LIMIT >= totalArticles
                                        ? "opacity-50 cursor-not-allowed"
                                        : "hover:bg-pink-400"
                                }`}
                            >
                                Página Siguiente
                            </button>
                        </div>
                    </div>
                ) : (
                    <p className="text-white text-center">No se encontraron artículos</p>
                )}
            </section>
        </div>
    );
};

export default ArticleList;

