import React from "react";
import Footer from "../components/Footer";

const MainLayout = ({ children }) => {
    return (
        <div className="bg-gray-900 text-white min-h-screen flex flex-col">
            {/*<Header />*/}
            <main className="flex-grow container mx-auto py-2 px-2">
                {children}
            </main>
            <Footer />
        </div>
    );
};

export default MainLayout;