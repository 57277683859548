import './App.css';
import MainLayout from "./layouts/MainLayout";
import ArticleList from "./components/ArticleList";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import ArticleDetail from "./components/ArticleDetail";
import ReelsList from "./components/ReelsList";

function App() {
    return (
        <BrowserRouter>
            <MainLayout>
                <Routes>
                    <Route path="/" element={<ArticleList />} />
                    <Route path="/articulos/:id" element={<ArticleDetail />} />
                    <Route path="/reels" element={<ReelsList />} />
                </Routes>
            </MainLayout>
        </BrowserRouter>
    );
}

export default App;
